import React from "react";
import { Route, Routes } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// scss files
import "./assets/css/ClientSignup.scss";
import "./assets/css/ClientLogin.scss";
import "./assets/css/ClientDashboard.scss";
import "./assets/css/ClientNavbar.scss";
import "./assets/css/AdminDashboard.scss";
import "./assets/css/AdminInstructorsList.scss";
import "./assets/css/AdminInstructorProfile.scss";
import "./assets/css/AddBookDrive.scss";
import "./assets/css/AdminBenefits.scss";
import "./assets/css/Addusers.scss";

// Client Pages
import ClientLogin from "./pages/client/Login/ClientLogin";
import ForgotPassword from "./pages/client/Login/ForgotPassword";
import ResetClientPassword from "./pages/client/Login/ResetClientPassword";
import Signup from "./pages/client/SignUp/Signup";
import PersonalDetails from "./pages/client/SignUp/PersonalDetails";
import Experience from "./pages/client/SignUp/Experience";
import PreferenceForLessons from "./pages/client/SignUp/PreferenceForLessons";
import PaymentMethod from "./pages/client/SignUp/PaymentMethod";
import MyLessons from "./pages/client/Dashboard/MyLessons/MyLessons";
import ConnectedInstructor from "./pages/client/Dashboard/ConnectedInstructor";
import FindInstructor from "./pages/client/Dashboard/FindInstructor";
import Benefits from "./pages/client/Dashboard/Benefits";
import UserProfile from "./pages/client/Dashboard/UserPofile/UserProfile";
import InstructorProfile from "./pages/client/Dashboard/InstructorProfile/InstructorProfile";
import EmailVerify from "./pages/client/Login/EmailVerify";
import Success from "./pages/client/Dashboard/Success";
import Cancel from "./pages/client/Dashboard/Cancel";
import Footer from "./layouts/Footer";
import NotaStudent from "./pages/client/Login/NotaStudent";
import UserProfileEdit from "./pages/client/Dashboard/UserPofile/UserProfileEdit";
import Protected from "./layouts/Protected";
import Auth from "./Auth";
import Notifications from "./pages/client/Dashboard/Notifications";
import { AdminDashboard } from "./pages/admin/Instructor/AdminDashboard";
import { AdminInstructors } from "./pages/admin/Instructor/AdminInstructors";
import { AdminInstructorProfile } from "./pages/admin/Instructor/AdminInstructorProfile";
import { DrivingSchoolInstructor } from "./pages/admin/Instructor/DrivingSchoolInstructor";
import InstructorCalendar from "./pages/admin/Instructor/instructorCalendar";
import Calendar from "./pages/admin/Calendar/Calendar";
import Student from "./pages/admin/Student/Student";
import { DrivingSchool } from "./pages/admin/DrivingSchools/DrivingSchool";
import { StudentProfile } from "./pages/admin/Student/StudentProfile";
import AddBookDrive from "./pages/admin/AddBookDrive/AddBookDrive";
import StripeConnect from "./pages/admin/StripeConnect/StripeConnect";
import StripeConnection from "./pages/admin/StripeConnect/StripeConnection";
import NewReport from "./pages/admin/Reports/NewReport";
import Reports from "./pages/admin/Reports/Reports";
import UploadBenefits from "./pages/admin/Settings/UploadBenefits";
import AddUser from "./pages/admin/Settings/AddUser";
import TermsAndCondition from "./layouts/TermsAndCondition";
import Policy from "./layouts/Policy";
import AdminProfileClient from "./pages/client/Dashboard/InstructorProfile/AdminProfileClient";
import CalendarInst from "./pages/admin/Calendar/CalendarInst";
import Notification from "./pages/admin/Notification/Notification";
import AdminProfile from "./pages/admin/AdminProfile";
import UnderMaintenance from "./layouts/UnderMaintenance";
import NotFound from "./layouts/NotFound";
import NextPage from "./NextPage";
import Membership from "./pages/client/Membership/Membership";

const environement = "development";
// const environement = "Maintenance";

const App = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {environement === "Maintenance" ? (
          <Routes>
            <Route path="*" element={<UnderMaintenance />} />
            {/* {/* <Route exact path="/" element={<ClientLogin />} /> */}
          </Routes>
        ) : (
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route exact path="/" element={<ClientLogin />} />
            <Route path="/auth" element={<Auth />} />
            <Route
              path="/nextPage"
              element={<Protected Component={NextPage} />}
              // element={<NextPage />}
            />
            <Route
              path="/clientPersonalDetails"
              // element={<Protected Component={PersonalDetails} />}
              element={<PersonalDetails />}
            />
            <Route
              path="/clientExperience"
              element={<Protected Component={Experience} />}
              // element={<Experience />}
            />
            <Route
              path="/preferenceForLessons"
              element={<Protected Component={PreferenceForLessons} />}
              // element={<PreferenceForLessons />}
            />
            <Route
              path="/payment"
              element={<Protected Component={PaymentMethod} />}
              // element={<PaymentMethod />}
            />
            <Route
              path="/myLessons"
              element={<Protected Component={MyLessons} />}
              // element={<MyLessons />}
            ></Route>
            <Route
              path="/connectedInstructor"
              // element={<ConnectedInstructor />}
              element={<Protected Component={ConnectedInstructor} />}
            ></Route>
            <Route
              path="/findInstructor"
              element={<FindInstructor />}
              // element={<Protected Component={FindInstructor} />}
            ></Route>
            <Route
              path="/benefits"
              // element={<Benefits />}
              element={<Protected Component={Benefits} />}
            ></Route>
            <Route
              path="/userProfile"
              // element={<UserProfile />}
              element={<Protected Component={UserProfile} />}
            ></Route>
            <Route
              path="/userProfileEdit"
              // element={<UserProfileEdit />}
              element={<Protected Component={UserProfileEdit} />}
            ></Route>
            <Route
              path="/emailVerify"
              element={<Protected Component={EmailVerify} />}
              // element={<EmailVerify />}
            ></Route>
            <Route
              path="/instructorProfile/:instructorId"
              element={<Protected Component={InstructorProfile} />}
              // element={<InstructorProfile />}
            ></Route>

            <Route
              path="/adminOrDrivingSchoolProfile/:adminId"
              element={<Protected Component={AdminProfileClient} />}
              // element={<AdminProfileClient />}
            ></Route>
            <Route
              path="/success/:insId"
              //  element={<Success />}
              element={<Protected Component={Success} />}
            />
            <Route
              path="/notAStudent"
              element={<Protected Component={NotaStudent} />}
              // element={<NotaStudent />}
            />
            <Route
              path="/membership"
              element={<Membership />}
              // element={<NotaStudent />}
            />
            <Route
              path="/cancel/:id"
              //  element={<Cancel />}
              element={<Protected Component={Cancel} />}
            />
            <Route
              path="/notifications"
              element={<Protected Component={Notifications} />}
              // element={<Notifications />}
            />
            <Route
              path="/admin/dashboard"
              element={<Protected Component={AdminDashboard} />}
              // element={<AdminDashboard />}
            />

            <Route
              path="/admin/drivingSchool"
              // element={<DrivingSchool />}
              element={<Protected Component={DrivingSchool} />}
            ></Route>
            <Route
              path="/admin/instructorsProfile/:Instid"
              // element={<AdminInstructorProfile />}
              element={<Protected Component={AdminInstructorProfile} />}
            />
            <Route
              path="/admin/instructor/instructorCalendar"
              element={<Protected Component={InstructorCalendar} />}
              // element={<InstructorCalendar />}
            />
            <Route
              path="/admin/calendar/:Adminid"
              //  element={<Calendar />}
              element={<Protected Component={Calendar} />}
            />
            <Route
              path="/inst/calendar/:Instid"
              //  element={<CalendarInst />}
              element={<Protected Component={CalendarInst} />}
            />
            <Route
              path="/admin/students"
              element={<Protected Component={Student} />}
              // element={<Student />}
            />
            <Route
              path="/admin/studentProfile/:studentid"
              element={<Protected Component={StudentProfile} />}
              // element={<StudentProfile />}
            />
            <Route
              path="/admin/addDrivingSchool"
              element={<Protected Component={AddBookDrive} />}
              // element={<AddBookDrive />}
            />
            <Route
              path="/admin/stripeConnect"
              element={<Protected Component={StripeConnect} />}
              // element={<StripeConnect />}
            />
            <Route
              path="/admin/newReport"
              element={<Protected Component={NewReport} />}
              // element={<NewReport />}
            />
            <Route
              path="/admin/report"
              // element={<Reports />}
              element={<Protected Component={Reports} />}
            />
            <Route
              path="/admin/uploadBenefits"
              element={<Protected Component={UploadBenefits} />}
              // element={<UploadBenefits />}
            />
            <Route
              path="/admin/addUsers"
              element={<Protected Component={AddUser} />}
              // element={<AddUser />}
            />
            <Route
              path="/admin/profile"
              element={<Protected Component={AdminProfile} />}
              // element={<AdminProfile />}
            />
            <Route
              path="/termsAndConditions"
              element={<Protected Component={TermsAndCondition} />}
              // element={<TermsAndCondition />}
            />
            <Route
              path="/policy"
              // element={<Policy />}
              element={<Protected Component={Policy} />}
            />
            <Route
              path="/admin/stripeConnection"
              element={<Protected Component={StripeConnection} />}
              // element={<StripeConnection />}
            />
            <Route
              path="/admin/notification"
              element={<Protected Component={Notification} />}
              // element={<Notification />}
            />
            <Route
              path="/admin/instructor/:DSid"
              element={<Protected Component={DrivingSchoolInstructor} />}
              // element={<DrivingSchoolInstructor />}
            />
            <Route
              path="/admin/instructors"
              element={<Protected Component={AdminInstructors} />}
              // element={<AdminInstructors />}
            />
          </Routes>
        )}
        <Footer />
      </LocalizationProvider>
    </div>
  );
};

export default App;
