import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const PlanCard = ({ title, amount, period, features, buttonText, isHighlighted }) => {
    return (
        <div
            className={`price--table--item ${isHighlighted ? "highlight" : ""
                }`}
        >
            <h2 className="heading--price">{title}</h2>
            <p className="price--plan">
                <span className="price--amount">${amount}</span> / {" "}{period}</p>
            <button
                className={`button ${isHighlighted ? "highlight--btn" : ""
                    }`}
            >
                {buttonText}
            </button>
            <ul className="lists--plan">
                {features.map((feature, index) => (
                    <li
                        key={index}
                        className={`list--plan--items ${feature.included ? isHighlighted ? "white-highlight" : "" : ""
                            }`}
                    >
                        <span>{feature.included ? <CheckCircleIcon /> : <CancelIcon />}</span>
                        {feature.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const PricingTable = () => {
    const plans = [
        {
            title: "Basic free",
            amount: "0",
            period: "Month",
            features: [
                { name: "Dashboard Insights", included: true },
                { name: "Client & Diary Management", included: true },
                { name: "Cancellations and Notifications", included: true },
                { name: "Max upto 3 Clients", included: true },
                { name: "Tech Support", included: true },
            ],
            buttonText: "Get Early Access",
            isHighlighted: false,
        },
        {
            title: "Premium",
            amount: "10",
            period: "Month",
            features: [
                { name: "Basic Plan plus", included: true },
                { name: "Unlimited Clients", included: true },
                { name: "Reporting Analytics", included: true },
                { name: "Solo & Multi - Instructor users", included: true },
                { name: "Driving School Admin", included: true },
                { name: "Delegate access", included: true },
                { name: "24 x 7 Priority Support", included: true },
            ],
            buttonText: "Get Early Access",
            isHighlighted: true,
        },
        {
            title: "Premium",
            amount: "90",
            period: "Yearly",
            features: [
                { name: "Basic Plan plus", included: true },
                { name: "Unlimited Clients", included: true },
                { name: "Reporting Analytics", included: true },
                { name: "Solo & Multi - Instructor users", included: true },
                { name: "Driving School Admin", included: true },
                { name: "Delegate access", included: true },
                { name: "24 x 7 Priority Support", included: true },
            ],
            buttonText: "Get Early Access",
            isHighlighted: false,
        },
    ];

    return (
        <>
            {plans.map((plan, index) => (
                <PlanCard key={index} {...plan} />
            ))}
        </>
    );
};

export default PricingTable;
